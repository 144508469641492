.layout-main-section {
  min-height: 950px;
}

.rbroLogo {
  display: none;
}

.rbroContainer input[type=checkbox] {
  -webkit-appearance: auto !important;
     -moz-appearance: auto !important;
          appearance: auto !important;
}

@font-face {
  font-family: "dejavusans";
  src: url("/assets/silicon_ioi/fonts/dejavusans/DejaVuSans.ttf");
}
@font-face {
  font-family: "dejavusanscondensed";
  src: url("/assets/silicon_ioi/fonts/dejavusanscondensed/DejaVuSansCondensed.ttf");
}
@font-face {
  font-family: "dejavuserif";
  src: url("/assets/silicon_ioi/fonts/dejavuserif/DejaVuSerif.ttf");
}
@font-face {
  font-family: "dejavuserifcondensed";
  src: url("/assets/silicon_ioi/fonts/dejavuserifcondensed/DejaVuSerifCondensed.ttf");
}
@font-face {
  font-family: "opensans";
  src: url("/assets/silicon_ioi/fonts/opensans/OpenSans.ttf");
}
@font-face {
  font-family: "roboto";
  src: url("/assets/silicon_ioi/fonts/roboto/Roboto.ttf");
}
@font-face {
  font-family: "archivo";
  src: url("/assets/silicon_ioi/fonts/archivo/Archivo-Regular.ttf");
}
@font-face {
  font-family: "archivo_thin";
  src: url("/assets/silicon_ioi/fonts/archivo_thin/Archivo-Thin.ttf");
}